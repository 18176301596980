import upperFirst from "lodash/upperFirst";
import uuidv1 from 'uuid/v1';
import { franchiseList } from './franchises';

const isEcho = name => ["daisy", "lucina", "richter", "dark-pit", "chrom", "ken"].includes(name);

const fallSpeeds = {
  "fox": 2.1, "king-dedede": 1.95, "little-mac": 1.95, "piranha-plant": 1.95, "mii-brawler": 1.92, "pichu": 1.9,
  "captain-falcon": 1.865, "simon": 1.85, "richter": 1.85, "greninja": 1.85, "wolf": 1.8, "falco": 1.8, "roy": 1.8,
  "chrom": 1.8, "young-link": 1.8, "mega-man": 1.8, "ridley": 1.78, "bowser": 1.77, "bayonetta": 1.77, "banjo-and-kazooie": 1.76,
  "incineroar": 1.76, "sheik": 1.75, "diddy-kong": 1.75, "snake": 1.73, "zero-suit-samus": 1.7, "king-k_-rool": 1.7,
  "cloud": 1.68, "lucario": 1.68, "meta-knight": 1.66, "sonic": 1.65, "corrin": 1.65, "ganondorf": 1.65, "ike": 1.65,
  "duck-hunt": 1.65, "bowser-jr_": 1.65, "donkey-kong": 1.63, "joker": 1.63, "wario": 1.61, "link": 1.6, "r_-o_-b": 1.6,
  "ryu": 1.6, "ken": 1.6, "marth": 1.58, "lucina": 1.58, "shulk": 1.58, "inkling": 1.58, "hero": 1.57, "palutena": 1.55,
  "mewtwo": 1.55, "pikachu": 1.55, "mii-swordfighter": 1.55, "mario": 1.5, "dr_-mario": 1.5, "robin": 1.5, "pit": 1.48,
  "dark-pit": 1.48, "mii-gunner": 1.45, "pokemon-trainer": 1.42, // avg of 3 characters
  "toon-link": 1.38, "lucas": 1.37, "olimar": 1.35, "pac-man": 1.35, "zelda": 1.35, "samus": 1.33, "dark-samus": 1.33,
  "luigi": 1.32, "villager": 1.32, "ness": 1.31, "ice-climbers": 1.3, "wii-fit-trainer": 1.3, "isabelle": 1.3, "yoshi": 1.29,
  "mr_-game-and-watch": 1.24, "kirby": 1.23, "rosalina-and-luma": 1.2, "peach": 1.19, "daisy": 1.19, "jigglypuff": 0.98,
  "terry-bogard": 1.48, "byleth": 1.6, "min-min": 1.5, "steve": 1.42,
};

const yearOfRelease = {
  "mario": 1981, "donkey-kong": 1981, "link": 1986, "samus": 1986, "dark-samus": 2002, "yoshi": 1990, "kirby": 1992,
  "fox": 1993, "pikachu": 1996, "luigi": 1983, "ness": 1994, "captain-falcon": 1990, "jigglypuff": 1996, "peach": 1985,
  "daisy": 1989, "bowser": 1985, "ice-climbers": 1985, "sheik": 1998, "zelda": 1986, "dr_-mario": 1990, "pichu": 1999,
  "falco": 1993, "marth": 1990, "lucina": 2012, "young-link": 1998, "ganondorf": 1991, "mewtwo": 1996, "roy": 2001,
  "chrom": 2012, "mr_-game-and-watch": 1980, "meta-knight": 1993, "pit": 1986, "dark-pit": 2012, "zero-suit-samus": 2004,
  "wario": 1992, "snake": 1987, "ike": 2005, "pokemon-trainer": 1996, // average of the three characters
  "diddy-kong": 1994, "lucas": 2006, "sonic": 1991, "king-dedede": 1992, "olimar": 2001, "lucario": 2005,
  "r_-o_-b": 1985, "toon-link": 2002, "wolf": 1997, "villager": 2001, "mega-man": 1987, "wii-fit-trainer": 2007,
  "rosalina-and-luma": 2007, "little-mac": 1983, "greninja": 2013, "palutena": 1986, "pac-man": 1980, "robin": 2012,
  "shulk": 2010, "bowser-jr_": 2002, "duck-hunt": 1984, "ryu": 1987, "ken": 1987, "cloud": 1997, "corrin": 2015,
  "bayonetta": 2009, "inkling": 2015, "ridley": 1986, "simon": 1986, "richter": 1993, "king-k_-rool": 1994,
  "isabelle": 2012, "incineroar": 2016, "piranha-plant": 1985, "joker": 2016, "hero": 1986,
  "banjo-and-kazooie": 1997, "terry-bogard": 1991, "byleth": 2019, "min-min": 2017, "mii-brawler": 2006,
  "mii-swordfighter": 2006, "mii-gunner": 2006, "steve": 2011,
};

const franchises = {
  "mario": franchiseList[0], "donkey-kong": franchiseList[1], "link": franchiseList[2],
  "samus": franchiseList[3], "dark-samus": franchiseList[3], "yoshi": franchiseList[0],
  "kirby": franchiseList[4], "fox": franchiseList[5], "pikachu": franchiseList[6],
  "luigi": franchiseList[0], "ness": franchiseList[7], "captain-falcon": franchiseList[8],
  "jigglypuff": franchiseList[6], "peach": franchiseList[0], "daisy": franchiseList[0],
  "bowser": franchiseList[0], "ice-climbers": franchiseList[9], "sheik": franchiseList[2],
  "zelda": franchiseList[2], "dr_-mario": franchiseList[10], "pichu": franchiseList[6],
  "falco": franchiseList[5], "marth": franchiseList[11], "lucina": franchiseList[11],
  "young-link": franchiseList[2], "ganondorf": franchiseList[2], "mewtwo": franchiseList[6],
  "roy": franchiseList[11], "chrom": franchiseList[11], "mr_-game-and-watch": franchiseList[12],
  "meta-knight": franchiseList[4], "pit": franchiseList[13], "dark-pit": franchiseList[13],
  "zero-suit-samus": franchiseList[3], "wario": franchiseList[0], "snake": franchiseList[14],
  "ike": franchiseList[11], "pokemon-trainer": franchiseList[6], "diddy-kong": franchiseList[1],
  "lucas": franchiseList[7], "sonic": franchiseList[15], "king-dedede": franchiseList[4],
  "olimar": franchiseList[16], "lucario": franchiseList[6], "r_-o_-b": franchiseList[17],
  "toon-link": franchiseList[2], "wolf": franchiseList[5], "villager": franchiseList[18],
  "mega-man": franchiseList[19], "wii-fit-trainer": franchiseList[20], "rosalina-and-luma": franchiseList[0],
  "little-mac": franchiseList[21], "greninja": franchiseList[6], "palutena": franchiseList[13],
  "pac-man": franchiseList[22], "robin": franchiseList[11], "shulk": franchiseList[23],
  "bowser-jr_": franchiseList[0], "duck-hunt": franchiseList[24], "ryu": franchiseList[25],
  "ken": franchiseList[25], "cloud": franchiseList[26], "corrin": franchiseList[11],
  "bayonetta": franchiseList[27], "inkling": franchiseList[28], "ridley": franchiseList[3],
  "simon": franchiseList[29], "richter": franchiseList[29], "king-k_-rool": franchiseList[1],
  "isabelle": franchiseList[18], "incineroar": franchiseList[6], "piranha-plant": franchiseList[0],
  "joker": franchiseList[30], "hero": franchiseList[31], "banjo-and-kazooie": franchiseList[32],
  "terry-bogard": franchiseList[33], "byleth": franchiseList[11], "min-min": franchiseList[34],
  "steve": franchiseList[35], "mii-brawler": franchiseList[36], "mii-swordfighter": franchiseList[36],
  "mii-gunner": franchiseList[36],
};

const smashAppearances = {
  "mario": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "donkey-kong": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "link": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "samus": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "dark-samus": ["ultimate"], "yoshi": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "kirby": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "fox": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "pikachu": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "luigi": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "ness": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "captain-falcon": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "jigglypuff": ["smash-bros", "melee", "brawl", "smash4", "ultimate"],
  "peach": ["melee", "brawl", "smash4", "ultimate"],
  "daisy": ["ultimate"], "bowser": ["melee", "brawl", "smash4", "ultimate"],
  "ice-climbers": ["melee", "brawl", "smash4", "ultimate"],
  "sheik": ["melee", "brawl", "smash4", "ultimate"],
  "zelda": ["melee", "brawl", "smash4", "ultimate"],
  "dr_-mario": ["melee", "smash4", "ultimate"], "pichu": ["melee", "ultimate"],
  "falco": ["melee", "brawl", "smash4", "ultimate"],
  "marth": ["melee", "brawl", "smash4", "ultimate"],
  "lucina": ["smash4", "ultimate"], "young-link": ["melee", "ultimate"],
  "ganondorf": ["melee", "brawl", "smash4", "ultimate"], "mewtwo": ["melee", "smash4", "ultimate"],
  "roy": ["melee", "smash4", "ultimate"], "chrom": ["smash4", "ultimate"],
  "mr_-game-and-watch": ["melee", "brawl", "smash4", "ultimate"], "meta-knight": ["brawl", "smash4", "ultimate"],
  "pit": ["brawl", "smash4", "ultimate"], "dark-pit": ["smash4", "ultimate"],
  "zero-suit-samus": ["brawl", "smash4", "ultimate"], "wario": ["brawl", "smash4", "ultimate"],
  "snake": ["brawl", "ultimate"], "ike": ["brawl", "smash4", "ultimate"],
  "pokemon-trainer": ["brawl", "smash4", "ultimate"], // ughhh we'll have to separate charizard for this...
  "diddy-kong": ["brawl", "smash4", "ultimate"], "lucas": ["brawl", "smash4", "ultimate"],
  "sonic": ["brawl", "smash4", "ultimate"], "king-dedede": ["brawl", "smash4", "ultimate"],
  "olimar": ["brawl", "smash4", "ultimate"], "lucario": ["brawl", "smash4", "ultimate"],
  "r_-o_-b": ["brawl", "smash4", "ultimate"], "toon-link": ["brawl", "smash4", "ultimate"],
  "wolf": ["brawl", "ultimate"], "villager": ["smash4", "ultimate"], "mega-man": ["smash4", "ultimate"],
  "wii-fit-trainer": ["smash4", "ultimate"], "rosalina-and-luma": ["smash4", "ultimate"],
  "little-mac": ["smash4", "ultimate"], "greninja": ["smash4", "ultimate"], "palutena": ["smash4", "ultimate"],
   "pac-man": ["smash4", "ultimate"], "robin": ["smash4", "ultimate"], "shulk": ["smash4", "ultimate"],
   "bowser-jr_": ["smash4", "ultimate"], "duck-hunt": ["smash4", "ultimate"], "ryu": ["smash4", "ultimate"],
   "ken": ["ultimate"], "cloud": ["smash4", "ultimate"], "corrin": ["smash4", "ultimate"],
  "bayonetta": ["smash4", "ultimate"], "inkling": ["ultimate"], "ridley": ["ultimate"],
  "simon": ["ultimate"], "richter": ["ultimate"], "king-k_-rool": ["ultimate"], "isabelle": ["ultimate"],
  "incineroar": ["ultimate"], "piranha-plant": ["ultimate"], "joker": ["ultimate"], "hero": ["ultimate"],
  "banjo-and-kazooie": ["ultimate"], "terry-bogard": ["ultimate"], "byleth": ["ultimate"],
  "min-min": ["ultimate"], "steve": ["ultimate"], "mii-brawler": ["smash4", "ultimate"],
  "mii-swordfighter": ["smash4", "ultimate"], "mii-gunner": ["smash4", "ultimate"],
};

const weights = {
  "mario": 98, "donkey-kong": 127, "link": 104, "samus": 108, "dark-samus": 108, "yoshi": 104, "kirby": 79,
  "fox": 77, "pikachu": 79, "luigi": 97, "ness": 94, "captain-falcon": 104, "jigglypuff": 68, "peach": 89,
  "daisy": 89, "bowser": 135, "ice-climbers": 92, "sheik": 78, "zelda": 85, "dr_-mario": 98, "pichu": 62,
  "falco": 82, "marth": 90, "lucina": 90, "young-link": 88, "ganondorf": 118, "mewtwo": 79, "roy": 95,
  "chrom": 95, "mr_-game-and-watch": 75, "meta-knight": 80, "pit": 96, "dark-pit": 96, "zero-suit-samus": 80,
  "wario": 107, "snake": 106, "ike": 107, "pokemon-trainer": 96, // average of the three characters
  "diddy-kong": 90, "lucas": 94, "sonic": 86, "king-dedede": 127, "olimar": 79, "lucario": 92,
  "r_-o_-b": 106, "toon-link": 91, "wolf": 92, "villager": 92, "mega-man": 102, "wii-fit-trainer": 96,
  "rosalina-and-luma": 82, "little-mac": 87, "greninja": 88, "palutena": 91, "pac-man": 95, "robin": 95,
  "shulk": 97, "bowser-jr_": 108, "duck-hunt": 86, "ryu": 103, "ken": 103, "cloud": 100, "corrin": 98,
  "bayonetta": 81, "inkling": 94, "ridley": 107, "simon": 107, "richter": 107, "king-k_-rool": 133,
  "isabelle": 88, "incineroar": 116, "piranha-plant": 112, "joker": 93, "hero": 101,
  "banjo-and-kazooie": 106, "terry-bogard": 108, "byleth": 97, "min-min": 104, "steve": 92,
  "mii-brawler": 94, "mii-swordfighter": 100, "mii-gunner": 104,
};

const speeds = {
  "mario": 1.76, "donkey-kong": 1.873, "link": 1.534, "samus": 1.654, "dark-samus": 1.654, "yoshi": 2.046,
  "kirby": 1.727, "fox": 2.402, "pikachu": 2.039, "luigi": 1.65, "ness": 1.609, "captain-falcon": 2.552,
  "jigglypuff": 1.271, "peach": 1.595, "daisy": 1.595, "bowser": 1.971, "ice-climbers": 1.683, "sheik": 2.42,
  "zelda": 1.43, "dr_-mario": 1.397792, "pichu": 1.892, "falco": 1.619, "marth": 1.964, "lucina": 1.964,
  "young-link": 1.749, "ganondorf": 1.34, "mewtwo": 2.255, "roy": 2.145, "chrom": 2.145, "mr_-game-and-watch": 1.679,
  "meta-knight": 2.09, "pit": 1.828, "dark-pit": 1.828, "zero-suit-samus": 2.31, "wario": 1.65, "snake": 1.595,
  "ike": 1.507, "pokemon-trainer": 1.852,  // average of the three characters
  "diddy-kong": 2.006, "lucas": 1.65, "sonic": 3.85, "king-dedede": 1.496, "olimar": 1.617, "lucario": 1.705,
  "r_-o_-b": 1.725, "toon-link": 1.906, "wolf": 1.54, "villager": 1.397, "mega-man": 1.602, "wii-fit-trainer": 1.866,
  "rosalina-and-luma": 1.795, "little-mac": 2.464, "greninja": 2.288, "palutena": 2.077, "pac-man": 1.672,
  "robin": 1.265, "shulk": 1.672, "bowser-jr_": 1.566, "duck-hunt": 1.793, "ryu": 1.6, "ken": 1.76,
  "cloud": 2.167, "corrin": 1.595, "bayonetta": 1.76, "inkling": 1.925, "ridley": 2.2, "simon": 1.52, "richter": 1.52,
  "king-k_-rool": 1.485, "isabelle": 1.48, "incineroar": 1.18, "piranha-plant": 1.72, "joker": 2.06,
  "hero": 1.84, "banjo-and-kazooie": 2.18, "terry-bogard": 1.749, "byleth": 1.43, "min-min": 1.566,
  "steve": 1.46, "mii-brawler": 1.92, "mii-swordfighter": 1.58, "mii-gunner": 1.37,
};

const hydrateCharacter = (filename, ind) => {
  const fullName = filename.replace("_", ".").replace("and", "&").split("-").map(name => {
    return upperFirst(name);
  }).join(" ");
  return {
    filename,
    name: filename === "pac-man" ? "PAC-MAN" : fullName,
    originalPlace: ind,
    weight: weights[filename],
    speed: speeds[filename],
    fallSpeed: fallSpeeds[filename],
    echo: isEcho(filename),
    franchise: franchises[filename],
    id: uuidv1(),
    appearances: smashAppearances[filename],
    yearOfRelease: yearOfRelease[filename]
  };
};

const characters = [
  "mario", "donkey-kong", "link", "samus", "dark-samus", "yoshi", "kirby", "fox",
  "pikachu", "luigi", "ness", "captain-falcon", "jigglypuff", "peach", "daisy", "bowser",
  "ice-climbers", "sheik", "zelda", "dr_-mario", "pichu", "falco", "marth", "lucina",
  "young-link", "ganondorf", "mewtwo", "roy", "chrom", "mr_-game-and-watch",
  "meta-knight", "pit", "dark-pit", "zero-suit-samus", "wario", "snake", "ike",
  "pokemon-trainer", "diddy-kong", "lucas", "sonic", "king-dedede", "olimar", "lucario",
  "r_-o_-b", "toon-link", "wolf", "villager", "mega-man", "wii-fit-trainer", "rosalina-and-luma",
  "little-mac","greninja","palutena","pac-man","robin","shulk", "bowser-jr_", "duck-hunt",
  "ryu", "ken", "cloud", "corrin", "bayonetta", "inkling", "ridley", "simon", "richter",
  "king-k_-rool", "isabelle", "incineroar", "piranha-plant", "joker", "hero",
  "banjo-and-kazooie", "terry-bogard", "byleth", "min-min", "steve", "mii-brawler",
  "mii-swordfighter", "mii-gunner",
].map((name, i) => {
  return hydrateCharacter(name, i);
});

export default characters;
