const franchiseNames = {
  'super-mario-bros': "Super Mario Bros",
  'donkey-kong': "Donkey Kong",
  'zelda': "Zelda",
  'metroid': "Metroid",
  'kirby': "Kirby",
  'star-fox': "Star Fox",
  'pokemon': "Pokémon",
  'earthbound': "Mother",
  'f-zero': "F-Zero",
  'ice-climbers': "Ice Climber",
  'dr-mario': "Dr. Mario",
  'fire-emblem': "Fire Emblem",
  'mr-game-and-watch': "Game & Watch",
  'kid-icarus': "Kid Icarus",
  'metal-gear': "Metal Gear",
  'sonic': "Sonic the Hedgehog",
  'pikmin': "Pikmin",
  'robot-series': "Robot Series",
  'animal-crossing': "Animal Crossing",
  'mega-man': "Mega Man",
  'wii-fit': "Wii Fit",
  'punch-out': "Punch-Out!!",
  'pac-man': "PAC-MAN",
  'xenoblade-chronicles': "Xenoblade Chronicles",
  'duck-hunt': "Duck Hunt",
  'street-fighter': "Street Fighter",
  'final-fantasy': "Final Fantasy",
  'bayonetta': "Bayonetta",
  'splatoon': "Splatoon",
  'castlevania': "Castlevania",
  'persona': "Persona",
  'dragon-quest': "Dragon Quest",
  'banjo-kazooie': "Banjo Kazooie",
  'fatal-fury': "Fatal Fury",
  'arms': 'ARMS',
  'mii': "Mii Maker",
  'minecraft': 'Minecraft',
};

const franchiseList = [
  'super-mario-bros', 'donkey-kong', 'zelda', 'metroid', 'kirby', 'star-fox', 'pokemon',
  'earthbound', 'f-zero', 'ice-climbers', 'dr-mario', 'fire-emblem', 'mr-game-and-watch',
  'kid-icarus', 'metal-gear', 'sonic', 'pikmin', 'robot-series', 'animal-crossing',
  'mega-man', 'wii-fit', 'punch-out', 'pac-man', 'xenoblade-chronicles', 'duck-hunt',
  'street-fighter', 'final-fantasy', 'bayonetta', 'splatoon', 'castlevania', 'persona',
  'dragon-quest', 'banjo-kazooie', 'fatal-fury', 'arms', 'minecraft', 'mii'
]

export { franchiseNames, franchiseList };
