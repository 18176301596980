import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, IconButton, ListItemAvatar, Avatar, ListItemText,
  ListItemSecondaryAction, Divider } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

const styles = theme => ({
  listItem: {
    marginTop: 5,
  },
  divider: {
    margin: "10px 20px",
  },
  playerName: {
    paddingLeft: 8
  }
});

const Player = ({player, classes, handleRemovePlayer, characterSet, playerColor, random, playerCount}) => {
  const character = player.index >= characterSet.length
    ? random
    : characterSet[player.index];
  return (<>
    <ListItem  className={classes.listItem}>
      <ListItemAvatar>
        <Avatar alt={character.name} style={{
            height: (playerCount < 5 ? 80 : 50),
            width: (playerCount < 5 ? 80 : 50),
            border: `5px solid ${playerColor}`
          }}
          src={character.firebasePath}
        />
      </ListItemAvatar>
      <ListItemText
        className={classes.playerName}
        primary={player.name}
      />
      <ListItemSecondaryAction>
        <IconButton edge="end" aria-label="Remove" onClick={() => handleRemovePlayer(player.id)}>
          <DeleteIcon style={{ color: playerColor }}/>
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
    <Divider className={classes.divider} style={{ backgroundColor: playerColor}} />
  </>)
};

export default withStyles(styles)(Player);
