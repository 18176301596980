import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";

import NavBar from './components/NavBar';
import CharacterSelect from './containers/CharacterSelect';
import About from './containers/About';
import firebase from '@firebase/app';
import '@firebase/auth';
import '@firebase/database';
import '@firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_MEASUREMENT_ID
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const AppRouter = (props) => {
  return (
    <>
      <Router>
        <NavBar {...props} />
        <Switch>
          <Route exact path="/" component={() => <CharacterSelect firebase={firebase} />} />
          <Route exact path="/about" component={() => <About />} />
        </Switch>
      </Router>
    </>
)};

export default AppRouter;
