import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

import AddPlayer from '../components/AddPlayer';
import Character from '../components/Character';
import PlayerList from '../components/PlayerList';
import ControlPanel from '../components/ControlPanel';
import CharacterModal from '../components/CharacterModal';
import MovePlayerModal from '../components/MovePlayerModal';

const styles = theme => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    alignItems: "flex-start",
    padding: 20,
    margin: "40px 0 0 0",
    backgroundColor: "#C4CDD8"
  },
  panelGrid: {
    margin: 0
  },
  playerIncrease: {
    width: 75,
    margin: 2
  },
  characterContainer: {
    justifyContent: "center",
    alignItems: 'flex-start',
    margin: 0
  },
  playersPaper: {
    width: "100%",
    height: "100%",
    padding: "25px 0 10px 0",
    margin: "0 10px"
  },
  divider: {
    margin: "10px 20px",
  }
});

const HomeLayout = ({
  classes,
  setPlayerInput,
  playerInput,
  handleAddPlayer,
  players,
  characterSet,
  removePlayer,
  playerColor,
  random,
  handlePlayersModalOpen,
  setCharacterSet,
  baseCharacters,
  handlePlayersModalClose,
  openPlayersModal,
  updateIndexIncreaser,
  updatePlayerIndex,
  increases,
  handleCharacterModalOpen,
  modalCharacter,
  handleCharacterModalClose,
  openCharacterModal,
  echoFilter,
  setEchoFilter,
  openFilters,
  setOpenFilters,
  currentFranchises,
  setCurrentFranchises,
  openFranchises,
  setOpenFranchises,
  sorting,
  setSorting,
  openSorters,
  setOpenSorters }) => (
    <Grid container spacing={1} className={classes.root}>
      {/* Control Panel */}
      <Grid container item className={classes.panelGrid} spacing={4} xs={12} sm={12} md={12} lg={4} xl={3}>
        <Paper className={classes.playersPaper}>
          {/* Add Players */}
          <AddPlayer
            handlePlayerInput={setPlayerInput}
            newPlayer={playerInput}
            handleAddPlayer={handleAddPlayer}
            players={players} />
          <Divider className={classes.divider} />
          {/* Player List */}
          <PlayerList players={players}
            characterSet={characterSet}
            handleRemovePlayer={removePlayer}
            handlePlayerColor={playerColor}
            random={random} />
          {/* Control Panel */}
          <ControlPanel handleOpenPlayerMoveModal={handlePlayersModalOpen}
            handleCharacterSet={setCharacterSet}
            originalCharacterSet={baseCharacters}
            currentCharacterSet={characterSet}
            players={players}
            echoFilter={echoFilter}
            setEchoFilter={setEchoFilter}
            openFilters={openFilters}
            setOpenFilters={setOpenFilters}
            currentFranchises={currentFranchises}
            setCurrentFranchises={setCurrentFranchises}
            openFranchises={openFranchises}
            setOpenFranchises={setOpenFranchises}
            sorting={sorting}
            setSorting={setSorting}
            openSorters={openSorters}
            setOpenSorters={setOpenSorters}
          />
        </Paper>
      </Grid>
      <MovePlayerModal players={players}
        handleClose={handlePlayersModalClose}
        open={openPlayersModal}
        handleUpdateIndexIncreaser={updateIndexIncreaser}
        handleUpdatePlayerIndex={updatePlayerIndex}
        increases={increases} />
      {/* Character Grid */}
      <Grid className={classes.characterContainer} container item alignItems="center" spacing={4} xs={12} sm={12} md={12} lg={8} xl={9} >
        {
          characterSet.length < 1
            ? null
            : characterSet.concat([random]).map((character, i) => (
              <Character key={character.id}
                characterSet={characterSet}
                handleOpen={handleCharacterModalOpen}
                characterIndex={i}
                character={character}
                players={players}
                handlePlayerColor={playerColor} />
            ))
        }
        <CharacterModal character={modalCharacter} handleClose={handleCharacterModalClose} open={openCharacterModal} />
      </Grid>
    </Grid>
  );

export default withStyles(styles)(HomeLayout);
