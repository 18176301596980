import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Modal, Table, TableHead, TableBody, TableRow, TableCell, Grid, Paper } from '@material-ui/core';
import { franchiseNames } from '../utils/franchises';

const styles = theme => ({
  modal: {
    padding: 20,
    outline: 'none !important',
    position: "absolute",
    minWidth: "30%",
    minHeight: 200,
    maxWidth: "100%",
    top: "50%",
    left: "50%",
    transform: " translate(-50%, -50%)",
    textAlign: 'center',
    overflowX: "auto"
  },
  characterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: "center",
    alignItems: 'center',
    alignContent: 'center',
    width: "100%",
    maxWidth: "90vw",
    minWidth: "60vw",
    margin: "0 auto"
  },
  image: {
    minWidth: 90,
    maxWidth: "100%",
    maxHeight: 350,
    border: "2px solid black",
    borderRadius: 8,
    margin: "0 auto"
  },
  table: {
    width: "100%"
  },
  characterName: {
    fontFamily: "SmashBros"
  }
});

const makeAppearanceName = (appearance) => {
  switch (appearance) {
    case "smash-bros":
      return "Super Smash Bros.";
    case "melee":
      return "Melee";
    case "brawl":
      return "Brawl";
    case "smash4":
      return "Smash 4";
    case "ultimate":
      return "Ultimate";
    default:
      return;
  };
}

const CharacterModal = ({ classes, character, handleClose, open }) => !character ? null : (
  <Modal aria-labelledby="move-players"
    aria-describedby="adjust-player-counts"
    open={open ? true : false}
    onClose={handleClose}>
    <Paper className={classes.modal} onClick={handleClose}>
      <Grid container spacing={16} className={classes.characterContainer}>
        <Grid item xs={12} sm={12} md={8} lg={6} xl={2}>
          <img className={classes.image} src={character.firebasePath} alt={character.name} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6} xl={10}>
          <h2 className={classes.characterName}>{character.name.toUpperCase()} ({character.originalPlace + 1})</h2>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell padding="dense" align="left">Weight</TableCell>
                <TableCell padding="dense" align="left">Speed</TableCell>
                <TableCell padding="dense" align="left">Fall Speed</TableCell>
                <TableCell padding="dense" align="left">Debut Year</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={character.filename}>
                <TableCell padding="dense" align="left">{character.weight}</TableCell>
                <TableCell padding="dense" align="left">{character.speed}</TableCell>
                <TableCell padding="dense" align="left">{character.fallSpeed}</TableCell>
                <TableCell padding="dense" align="left">{character.yearOfRelease}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Table size="small" className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell align="left">Smash Appearances</TableCell>
                <TableCell padding="dense" align="left">Franchise</TableCell>
                <TableCell padding="dense" align="left">Is Echo?</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={character.filename}>
                <TableCell component='th' scope="row" align="left">{character.appearances.map(makeAppearanceName).join(", ")}</TableCell>
                <TableCell padding="dense" align="left">{franchiseNames[character.franchise]}</TableCell>
                <TableCell padding="dense" align="left">{character.echo ? "Yes" : "No"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </Paper>
  </Modal>
);

export default withStyles(styles)(CharacterModal);
