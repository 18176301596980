import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, Collapse, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore, Games as GamesIcon, LeakAdd as EchoIcon, FilterList as FiltersIcon } from '@material-ui/icons';

import Sorter from './Sorter.js';

const styles = theme => ({
  listItem: {
    borderRadius: 8
  },
  nestedList: {
    marginLeft: 15
  }
});


const Filters = ({
  classes,
  handleCharacterSet,
  originalCharacterSet,
  currentCharacterSet,
  echoFilter,
  setEchoFilter,
  openFilters,
  setOpenFilters }) => {
  const handleEchoFilter = () => {
    const echoes = originalCharacterSet.filter(character => character.echo);
    const noEchoes = currentCharacterSet.filter(character => !character.echo);
    const newCharacterSet = echoFilter
      ? echoes
      : (noEchoes.length < 1
        ? originalCharacterSet.filter(character => !character.echo)
        : noEchoes);
    setEchoFilter(!echoFilter);
    handleCharacterSet(newCharacterSet);
  };
  return <>
    <ListItem className={classes.listItem} button onClick={() => setOpenFilters(!openFilters)}>
      <ListItemIcon>
        <FiltersIcon />
      </ListItemIcon>
      <ListItemText primary="Filters" />
      {openFilters ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={openFilters} timeout="auto" unmountOnExit>
      <List component="div" className={classes.nestedList}>
        <Sorter handleSort={handleEchoFilter} sortText="Echoes" icon={<EchoIcon />} />
        <Sorter handleSort={() => handleCharacterSet(originalCharacterSet.filter(character => character.appearances.includes('smash-bros')))} sortText="Smash64" icon={<GamesIcon />} />
        <Sorter handleSort={() => handleCharacterSet(originalCharacterSet.filter(character => character.appearances.includes('melee')))} sortText="Melee" icon={<GamesIcon />} />
        <Sorter handleSort={() => handleCharacterSet(originalCharacterSet.filter(character => character.appearances.includes('brawl')))} sortText="Brawl" icon={<GamesIcon />} />
        <Sorter handleSort={() => handleCharacterSet(originalCharacterSet.filter(character => character.appearances.includes('smash4')))} sortText="Smash 4" icon={<GamesIcon />} />
        <Sorter handleSort={() => handleCharacterSet(originalCharacterSet)} sortText="Ultimate" icon={<GamesIcon />} />
      </List>
    </Collapse>
  </>
};

export default withStyles(styles)(Filters);
