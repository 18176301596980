import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';
import { franchiseNames } from '../utils/franchises';

const styles = theme => ({
  listItem: {
    borderRadius: 8
  },
  nestedList: {
    marginLeft: 15
  }
});

const Franchise = ({ classes, handleFranchisesSwitch, currentFranchises, franchise, index }) => (
  <ListItem role={undefined} dense>
    <ListItemIcon>
      <Checkbox
        onClick={() => handleFranchisesSwitch(franchise)}
        edge="start"
        checked={currentFranchises[franchise]}
        tabIndex={-1}
        disableRipple
        inputProps={{ 'aria-labelledby': franchise }}
      />
    </ListItemIcon>
    <ListItemText id={`${franchise}_${index}`} primary={franchiseNames[franchise]} />
  </ListItem>
);

export default withStyles(styles)(Franchise);
