import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper,  Button, Modal } from '@material-ui/core';

import PlayerIndexUpdater from './PlayerIndexUpdater.js'

const styles = theme => ({
  modal: {
    padding: 20,
    outline: 'none !important',
    position: "absolute",
    minWidth: "30%",
    minHeight: 200,
    maxWidth: "100%",
    top: "50%",
    left: "50%",
    transform: " translate(-50%, -50%)",
    textAlign: 'center'
  },
  playerIndexUpdatersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: "center",
    alignItems: 'center',
    alignContent: 'center',
    width: "100%",
    minWidth: 300,
  },
  movePlayerButton: {
    margin: "0 auto",
    width: "100%"
  }
});

const MovePlayerModal = ({ classes, players, handleClose, open, handleUpdateIndexIncreaser, handleUpdatePlayerIndex, increases }) => (
  <Modal aria-labelledby="move-players"
    aria-describedby="adjust-player-counts"
    open={open}
    onClose={handleClose}>
    <Paper className={classes.modal}>
      <div className={classes.playerIndexUpdatersContainer}>
        {
          players.map(player => (
            <PlayerIndexUpdater key={player.id}
              player={player}
              increases={increases}
              handlePlayerIndexUpdater={handleUpdateIndexIncreaser} />
          ))
        }
      </div>
      <Button
        variant="contained"
        className={classes.movePlayerButton}
        onClick={handleUpdatePlayerIndex}
        color="secondary">
        Move Players
      </Button>
    </Paper>
  </Modal>
);

export default withStyles(styles)(MovePlayerModal);
