import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, List } from '@material-ui/core';

import Player from './Player';

const styles = theme => ({
  list: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

const PlayerList = ({ classes, players, characterSet, handleRemovePlayer, handlePlayerColor, random }) => (
  players.length < 1
    ? null
    : <Grid container item spacing={0} xs={12} xl={12}>
        <Grid item xs={12} lg={players.length < 5 ? 12 : 6}>
          <List className={classes.list}>
            {players.reduce((acc, player, i) => (
              i > 3 ? acc : acc.concat([<Player key={player.id}
                characterSet={characterSet}
                playerCount={players.length}
                player={player}
                handleRemovePlayer={handleRemovePlayer}
                random={random}
                playerColor={handlePlayerColor(i)}
              />])
            ), [])}
          </List>
        </Grid>
        {
          players.length < 4
            ? null
            : <Grid item xs={12} lg={6}>
              <List className={classes.list}>
                {players.reduce((acc, player, i) => (
                  i < 4 ? acc : acc.concat([<Player key={player.id}
                    characterSet={characterSet}
                    playerCount={players.length}
                    player={player}
                    handleRemovePlayer={handleRemovePlayer}
                    random={random}
                    playerColor={handlePlayerColor(i)}
                  />])
                ), [])}
              </List>
            </Grid>
        }
      </Grid>
);

export default withStyles(styles)(PlayerList);



