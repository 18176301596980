import React, { Fragment, Component } from 'react';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';

import SmashTheme from './utils/Theme';
import AppRouter from './routes';

class App extends Component {
  render() {
    return (
      <Fragment>
        <SmashTheme>
          <CssBaseline />
          <AppRouter />
        </SmashTheme>
      </Fragment>
    );
  }
}

export default App;
