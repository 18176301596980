import React, {useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, Collapse, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore, LocationCity as BusinessIcon } from '@material-ui/icons';
import { franchiseList } from '../utils/franchises';

import Franchise from './Franchise';

const styles = theme => ({
  listItem: {
    borderRadius: 8
  },
  nestedList: {
    marginLeft: 15
  }
});

const Franchises = ({
  classes,
  handleCharacterSet,
  currentCharacterSet,
  originalCharacterSet,
  currentFranchises,
  setCurrentFranchises,
  openFranchises,
  setOpenFranchises }) => {
  const updateFranchises = () => {
    setCurrentFranchises(franchiseList.reduce((prev, curr) => {
      prev[curr] = currentCharacterSet.some(character => character.franchise === curr);
      return prev;
    }, {}));
  }
  useEffect(() => {
    updateFranchises();
  }, [originalCharacterSet]);
  const franchisesSwitch = (franchise) => {
    const newFranchises = Object.assign(currentFranchises, { [franchise]: !currentFranchises[franchise] });
    const newCharacterSet = originalCharacterSet.filter(character => newFranchises[character.franchise]);
    handleCharacterSet(newCharacterSet);
    setCurrentFranchises(newFranchises);
  }
  return (<>
    <ListItem className={classes.listItem} button onClick={() => setOpenFranchises(!openFranchises)}>
      <ListItemIcon>
        <BusinessIcon />
      </ListItemIcon>
      <ListItemText primary="Franchise List" />
      {openFranchises ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={openFranchises} timeout="auto" unmountOnExit>
      <List component="div" className={classes.nestedList}>
        {franchiseList.map((franchise, i) => (
          <Franchise
            key={`${franchise}_${i}`}
            index={i}
            franchise={franchise}
            currentFranchises={currentFranchises}
            handleFranchisesSwitch={franchisesSwitch} />
        ))}
      </List>
    </Collapse>
  </>);
};

export default withStyles(styles)(Franchises);
