import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemIcon, Collapse, ListItemText } from '@material-ui/core';
import {
  Shuffle as ShuffleIcon, SettingsBackupRestore as SettingsBackupRestoreIcon,
  FastForward as FastForwardIcon, FitnessCenter as FitnessCenterIcon, Sort as SortIcon,
  LocationCity as BusinessIcon, VerticalAlignBottom as VerticalAlignBottomIcon, Cake as CakeIcon,
  SortByAlpha as AToZIcon, ExpandLess, ExpandMore
} from '@material-ui/icons';
import { shuffle, sortBy, reverse } from 'lodash';

import Sorter from './Sorter';
import {franchiseList} from '../utils/franchises';

const styles = theme => ({
  listItem: {
    borderRadius: 8
  },
  nestedList: {
    marginLeft: 15
  }
});

const Sorters = ({
  classes,
  handleCharacterSet,
  currentCharacterSet,
  sorting,
  setSorting,
  openSorters,
  setOpenSorters }) => {
  const sortingOn = (category) => {
    let newSorting = {
      name: false, weight: false, speed: false, fallSpeed: false,
      yearOfRelease: false, franchise: false, originalPlace: true
    };
    if (category === "random") {
      handleCharacterSet(shuffle(currentCharacterSet))
    } else if (category === "franchise") {
      const franchiseOrder = franchiseList.reduce((prev, franchise) => {
        return prev.concat(currentCharacterSet.filter(character => character.franchise === franchise));
      }, []);
      newSorting[category] = !sorting[category];
      handleCharacterSet(sorting[category] ? reverse(franchiseOrder) : franchiseOrder);
    } else {
      newSorting[category] = !sorting[category];
      category === "yearOfRelease" || category === "name"
        ? sorting[category]
          ? handleCharacterSet(reverse(sortBy(currentCharacterSet, c => c[category])))
          : handleCharacterSet(sortBy(currentCharacterSet, c => c[category]))
        : sorting[category]
          ? handleCharacterSet(sortBy(currentCharacterSet, c => c[category]))
          : handleCharacterSet(reverse(sortBy(currentCharacterSet, c => c[category])));
    }
    setSorting(newSorting);
  }
  return (<>
    <ListItem className={classes.listItem} button onClick={() => setOpenSorters(!openSorters)}>
      <ListItemIcon>
        <SortIcon />
      </ListItemIcon>
      <ListItemText primary="Sort Order" />
      {openSorters ? <ExpandLess /> : <ExpandMore />}
    </ListItem>
    <Collapse in={openSorters} timeout="auto" unmountOnExit>
      <List component="div" className={classes.nestedList}>
        <Sorter handleSort={() => sortingOn('originalPlace')} sortText="Original" icon={<SettingsBackupRestoreIcon />} />
        <Sorter handleSort={() => sortingOn('random')} sortText="Shuffle" icon={<ShuffleIcon />} />
        <Sorter handleSort={() => sortingOn('name')} sortText="Alphabetical" icon={<AToZIcon />} />
        <Sorter handleSort={() => sortingOn('speed')} sortText="Speed" icon={<FastForwardIcon />} />
        <Sorter handleSort={() => sortingOn('weight')} sortText="Weight" icon={<FitnessCenterIcon />} />
        <Sorter handleSort={() => sortingOn('yearOfRelease')} sortText="Age" icon={<CakeIcon />} />
        <Sorter handleSort={() => sortingOn('fallSpeed')} sortText="Fall Speed" icon={<VerticalAlignBottomIcon />} />
        <Sorter handleSort={() => sortingOn('franchise')} sortText="Franchise" icon={<BusinessIcon />} />
      </List>
    </Collapse>
  </>);
};

export default withStyles(styles)(Sorters);
