import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, TextField } from '@material-ui/core';
import { ArrowUpward as ArrowUpwardIcon, ArrowDownward as ArrowDownwardIcon } from '@material-ui/icons';

const styles = theme => ({
  indexUpdateContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: "space-evenly",
    alignItems: 'center',
    alignContent: 'center',
    width: 80,
    margin: "15px 10px"
  },
});

const PlayerIndexUpdater = ({ increases, classes, handlePlayerIndexUpdater, player }) => (
  <div className={classes.indexUpdateContainer}>
    <IconButton aria-label="increase" onClick={() => handlePlayerIndexUpdater(player, "increase", null)}>
      <ArrowUpwardIcon />
    </IconButton>
    <TextField
      className={classes.playerIncrease}
      id="player-count"
      label={player.name}
      value={Number.isInteger(increases[player.id]) ? increases[player.id] : ""}
      onChange={(e) => {
        console.log(e.target.value)
        handlePlayerIndexUpdater(player, "", parseInt(e.target.value))
      }}
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      margin="normal"
      variant="outlined"
    />
    <IconButton aria-label="decrease" onClick={() => handlePlayerIndexUpdater(player, "decrease", null)}>
      <ArrowDownwardIcon />
    </IconButton>
  </div>
);

export default withStyles(styles)(PlayerIndexUpdater);
