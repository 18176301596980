import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import uuidv1 from 'uuid/v1';

const styles = theme => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    maxWidth: "100%",
    margin: "0 20px 20px 20px"
  },
  input: {
    marginLeft: 5,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 2,
    height: 30,
    margin: 2
  },
});

const playerTemplate = (newPlayer) => ({
  name: newPlayer,
  index: 0,
  id: uuidv1()
});

function AddPlayer({ classes, newPlayer, handlePlayerInput, handleAddPlayer, players }) {
  const addPlayer = (e) => {
    e.preventDefault();
    if (newPlayer.length > 0) {
      return handleAddPlayer(playerTemplate(newPlayer))
    }
    return false;
  }
  return players.length >= 8 ? null : (
    <form onSubmit={addPlayer}>
      <Paper className={classes.root}>
          <InputBase
            className={classes.input}
            placeholder="G4m3r T4g"
            inputProps={{ 'aria-label': 'Insert Player Name' }}
            onChange={(e) => handlePlayerInput(e.target.value)}
            value={newPlayer}
          />
          <Divider className={classes.divider} color="primary" orientation="vertical" />
          <IconButton color="primary"
            type="submit"
            className={classes.iconButton}
            aria-label="Add Player">
            <AddIcon />
          </IconButton>
      </Paper>
    </form>
  );
}

export default withStyles(styles)(AddPlayer);
