import React, {useEffect} from 'react';
import uuidv1 from 'uuid/v1';
import { withStyles } from '@material-ui/core/styles';
import characters from '../utils/characters';

import HomeLayout from '../components/HomeLayout';

const playerColor = (index) => {
  return {
    0: "#F70600",
    1: "#0C11D1",
    2: "#FFE500",
    3: "#32D000",
    4: "#FA9400",
    5: "#00BACB",
    6: "#FB79D4",
    7: "#6001C0",
  }[index];
};

const styles = theme => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    alignItems: "flex-start",
    padding: 30,
    margin: 0,
    backgroundColor: "#C4CDD8"
  },
  panelGrid: {
    margin: 0
  },
  playerIncrease: {
    width: 75,
    margin: 2
  },
  characterContainer: {
    justifyContent: "center",
    alignItems: 'flex-start',
    margin: 0
  },
  playersPaper: {
    width: "100%",
    height: "100%",
    padding: "10px 0",
    margin: "0 10px"
  },
  titleContainer: {
    width: "100%",
    textAlign: 'center'
  },
  title: {
    margin: "20px auto",
    fontFamily: "SmashBros",
    textShadow: "0px 0px 6px #ccc",
    fontSize: 50,
    [theme.breakpoints.down('xs')]: {
      fontSize: 30
    }
  },
  divider: {
    margin: "10px 20px",
  }
});

const random = {
  filename: 'random',
  name: 'Random',
  filePath: "/img/random.jpg",
  originalPlace: characters.length,
  id: uuidv1()
};

const CharacterSelect = ({ firebase }) => {
  // STATE
  const [baseCharacters, setBaseCharacters] = React.useState([]);
  const [characterSet, setCharacterSet] = React.useState(localStorage.characterSet
    ? JSON.parse(localStorage.characterSet)
    : []);
  const [players, setPlayers] = React.useState(localStorage.players
    ? JSON.parse(localStorage.players)
    : []);
  //
  const [playerInput, setPlayerInput] = React.useState("");
  const [openPlayersModal, setOpenPlayersModal] = React.useState(false);
  const [increases, setIncreases] = React.useState({});
  // modal for a character's stats
  const [openCharacterModal, setOpenCharacterModal] = React.useState(false);
  const [modalCharacter, setModalCharacter] = React.useState(null);
  // sorters for character set
  const [sorting, setSorting] = React.useState({
    name: false, weight: false, speed: false, fallSpeed: false,
    yearOfRelease: false, franchise: false, originalPlace: true,
  });
  const [openSorters, setOpenSorters] = React.useState(false);
  // filters for character set
  const [echoFilter, setEchoFilter] = React.useState(false);
  const [openFilters, setOpenFilters] = React.useState(false);
  // franchise state
  const [currentFranchises, setCurrentFranchises] = React.useState({});
  const [openFranchises, setOpenFranchises] = React.useState(false);
  // ------------------------------------------------------------
  // FIREBASE
  const storage = firebase.storage();
  const fetchCharacters = async () => {
    // preserve order from last localstorage save
    const chars = characterSet.length ? characterSet : characters;
    const newCharacters = await Promise.all(chars.map(async character => {
      character.firebasePath = await storage.ref(`/characters/ultimate/${character.filename}.jpg`).getDownloadURL();
      return character;
    }));
    random.firebasePath = await storage.ref(`/characters/ultimate/${random.filename}.jpg`).getDownloadURL();
    setCharacterSet(newCharacters);
    setBaseCharacters(newCharacters);
  };
  useEffect(() => {
    fetchCharacters();
  }, []);
  // ------------------------------------------------------------
  // UPDATE STATE METHODS
  const handlePlayersModalOpen = () => players.length ? setOpenPlayersModal(true) : null;
  const handlePlayersModalClose = () => setOpenPlayersModal(null);
  const handleCharacterModalOpen = (character) => {
    // break for character modal on random character
    if (character.filename === "random") return;
    setModalCharacter(character);
    setOpenCharacterModal(true);
  };
  const handleCharacterModalClose = () => {
    setOpenCharacterModal(false);
    setModalCharacter(null);
  };
  const removePlayer = (playerId) => {
    localStorage.clear();
    return setPlayers(players.filter(player => player.id !== playerId))
  };
  const handleAddPlayer = (newPlayer) => {
    if (players.length < 8) {
      setPlayers(players.concat([newPlayer]));
      setPlayerInput("");
      return true;
    }
    return false;
  };
  // increment by 1 or -1, or take the number in the input
  const updateIndexIncreaser = (player, job, value) => {
    const updatedIncreases = Object.assign({}, increases);
    if (job === "increase") {
      updatedIncreases[player.id] = updatedIncreases[player.id] === undefined ? 1 : updatedIncreases[player.id] + 1;
    } else if (job === "decrease") {
      updatedIncreases[player.id] = updatedIncreases[player.id] === undefined ? -1 : updatedIncreases[player.id] - 1;
    } else {
      updatedIncreases[player.id] = Number.isInteger(value) ? value : undefined;
    }
    setIncreases(updatedIncreases);
  };
  // move a player on the board
  const updatePlayerIndex = () => {
    setPlayers(players.map(player => {
      player.index = increases[player.id] === undefined ? player.index : player.index + increases[player.id];
      if (player.index < 0) player.index = 0;
      if (player.index > characterSet.length) player.index = characterSet.length;
      return player;
    }));
    handlePlayersModalClose();
    setIncreases({});
    localStorage.players = JSON.stringify(players);
    localStorage.characterSet = JSON.stringify(characterSet);
  };
  return (
    <HomeLayout
      setPlayerInput={setPlayerInput}
      playerInput={playerInput}
      handleAddPlayer={handleAddPlayer}
      players={players}
      characterSet={characterSet}
      removePlayer={removePlayer}
      playerColor={playerColor}
      random={random}
      handlePlayersModalOpen={handlePlayersModalOpen}
      setCharacterSet={setCharacterSet}
      baseCharacters={baseCharacters}
      handlePlayersModalClose={handlePlayersModalClose}
      openPlayersModal={openPlayersModal}
      updateIndexIncreaser={updateIndexIncreaser}
      updatePlayerIndex={updatePlayerIndex}
      increases={increases}
      handleCharacterModalOpen={handleCharacterModalOpen}
      modalCharacter={modalCharacter}
      handleCharacterModalClose={handleCharacterModalClose}
      openCharacterModal={openCharacterModal}
      echoFilter={echoFilter}
      setEchoFilter={setEchoFilter}
      openFilters={openFilters}
      setOpenFilters={setOpenFilters}
      currentFranchises={currentFranchises}
      setCurrentFranchises={setCurrentFranchises}
      openFranchises={openFranchises}
      setOpenFranchises={setOpenFranchises}
      sorting={sorting}
      setSorting={setSorting}
      openSorters={openSorters}
      setOpenSorters={setOpenSorters}
    />
  );
};

export default withStyles(styles)(CharacterSelect);
