import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';


const styles = theme => ({
  listItem: {
    borderRadius: 8
  }
});

const Sorter = ({ classes, handleSort, sortText, icon, players }) => (
  <ListItem disabled={players !== undefined && players.length < 1} button className={classes.listItem} onClick={handleSort}>
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText
      primary={sortText}
    />
  </ListItem>
);

export default withStyles(styles)(Sorter);
