import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const colors = {
  red: "rgb(251, 12, 28)",
  darkred: "rgb(187, 34, 34)",
  player0: "#F70600",
  player1: "#0C11D1",
  player2: "#FFE500",
  player3: "#32D000",
  player4: "#FA9400",
  player5: "#00BACB",
  player6: "#FB79D4",
  player7: "#6001C0",
}

const theme = createMuiTheme({
  colors: colors,
  // https://material-ui.com/style/typography/#migration-to-typography-v2
  typography: {
    useNextVariants: true,
  },
});

function SmashTheme(props) {
  return (
    <MuiThemeProvider theme={theme}>
      {props.children}
    </MuiThemeProvider >
  );
}

export default SmashTheme;
