import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { List, Divider } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';

import Sorter from './Sorter';
import Sorters from './Sorters';
import Filters from './Filters';
import Franchises from './Franchises';

const styles = theme => ({
  list: {
    margin: "0px 20px",
    paddingTop: 0
  },
  divider: {
    margin: "10px 0",
  },
  listItem: {
    borderRadius: 8
  },
  nestedList: {
    marginLeft: 15
  }
});

const ControlPanel = ({
  classes,
  handleOpenPlayerMoveModal,
  handleCharacterSet,
  originalCharacterSet,
  currentCharacterSet,
  players,
  echoFilter,
  setEchoFilter,
  openFilters,
  setOpenFilters,
  currentFranchises,
  setCurrentFranchises,
  openFranchises,
  setOpenFranchises,
  sorting,
  setSorting,
  openSorters,
  setOpenSorters }) => (
  <List className={classes.list}>
    <Sorter handleSort={handleOpenPlayerMoveModal} players={players} sortText="Move Players" icon={<ArrowForwardIcon />} />
    <Divider className={classes.divider} />
    {/* Sorters */}
    <Sorters
      handleCharacterSet={handleCharacterSet}
      currentCharacterSet={currentCharacterSet}
      sorting={sorting}
      setSorting={setSorting}
      openSorters={openSorters}
      setOpenSorters={setOpenSorters}
    />
    <Divider className={classes.divider} />
    {/* Filters */}
    <Filters
      handleCharacterSet={handleCharacterSet}
      currentCharacterSet={currentCharacterSet}
      originalCharacterSet={originalCharacterSet}
      echoFilter={echoFilter}
      setEchoFilter={setEchoFilter}
      openFilters={openFilters}
      setOpenFilters={setOpenFilters}
    />
    <Divider className={classes.divider} />
    {/* Franchises */}
    <Franchises
      handleCharacterSet={handleCharacterSet}
      currentCharacterSet={currentCharacterSet}
      originalCharacterSet={originalCharacterSet}
      currentFranchises={currentFranchises}
      setCurrentFranchises={setCurrentFranchises}
      openFranchises={openFranchises}
      setOpenFranchises={setOpenFranchises}
    />
  </List>
);

export default withStyles(styles)(ControlPanel);
