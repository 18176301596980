import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardMedia } from '@material-ui/core';

const styles = theme => ({
  card: {
    height: 100,
    width: 110,
    padding: "5px 0px 5px 5px",
    margin: 5,
    display: "flex",
    [theme.breakpoints.down('xs')]: {
      padding: "3px 0 3px 3px",
      height: 76,
      width: 86
    }
  },
  cardImg: {
    height: 90,
    width: 90,
    [theme.breakpoints.down('xs')]: {
      height: 70,
      width: 70
    }
  },
  playerChit: {
    width: 10,
    borderBottom: '1px solid #AAA',
    flexGrow: 1
  },
  playerList: {
    height: "115%",
    marginTop: -7,
    padding: "0 0 0 5px",
    display: "flex",
    alignItems: 'stretch',
    flexDirection: "column"
  }
});

const Character = ({classes, character, players, handlePlayerColor, characterIndex, characterSet, handleOpen}) => {
  const raiseCharacter = (index = null) => {
    if (index === null) {
      return players.some(player => player.index >= characterSet.length);
    }
    return players.some(player => player.index === index)
  };
  const playerColors = players.reduce((pV, cV, i) => {
    if (cV.index === characterIndex) {
      pV.push(handlePlayerColor(i));
    }
    return pV;
  }, []);
  return (
    <Card className={classes.card} raised={raiseCharacter(characterIndex)} onClick={() => handleOpen(character)}>
      <CardMedia className={classes.cardImg}
        image={character.firebasePath}
        title={character.name}
        alt={character.name}
      />
      {
        playerColors.length > 0
          ? <div className={classes.playerList}>
              {
                playerColors.map(playerColor => (
                  <span key={playerColor} className={classes.playerChit}
                    style={playerColor ? { backgroundColor: playerColor } : { display: 'none' }}>
                  </span>
                ))
              }
            </div>
          : null
      }
    </Card>
  );
};

export default withStyles(styles)(Character);
