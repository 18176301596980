import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, Grid, Divider, Typography, List, ListItem, ListItemText, ListItemIcon,
Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import { AccessibilityNew as LifeIcon, AccessTime as TimeIcon, Terrain as StageIcon, FlashOn as HazardsIcon,
  Gavel as ItemsIcon } from '@material-ui/icons'

const styles = theme => ({
  root: {
    justifyContent: "center",
    flexWrap: "wrap",
    width: "100%",
    minHeight: "100vh",
    height: "100%",
    alignItems: "flex-start",
    padding: 20,
    margin: "40px 0 0 0",
    backgroundColor: "#C4CDD8"
  },
  divider: {
    margin: "10px 20px",
  },
  title: {
    fontFamily: "SmashBros",
    textShadow: "0px 0px 4px #777",
    [theme.breakpoints.down('xs')]: {
      fontSize: 35
    }
  },
  subtitle: {
    fontFamily: "SmashBros"
  },
  siteInfo: {
    fontFamily: "SmashBros",
    paddingBottom: 10,
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  rulesPaper: {
    width: "95%",
    padding: "15px 40px 40px 40px",
    textAlign: "center"
  },
  rulesGrid: {
    paddingTop: 20
  },
  description: {
    fontSize: "20px",
    maxWidth: "70%",
    margin: "0 auto"
  },
  nested: {
    paddingLeft: 5
  }
});

const About = ({ classes }) => (
    <Grid container spacing={1} className={classes.root}>
      <Paper className={classes.rulesPaper}>
        <Typography variant='h2' className={classes.title}>
          HOW TO PLAY
        </Typography>
        <Divider className={classes.divider} />
        <Typography variant="h6" className={classes.description}>
          Earn movements through playing and move across the board (character select screen). <br />
          <strong>To win, you must get 1st on random.</strong>
        </Typography>
        <Divider className={classes.divider} />
        <Grid container justify="center" spacing={10} className={classes.rulesGrid}>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="h5" className={classes.subtitle}>
              RULESET
            </Typography>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem>
                <ListItemIcon>
                  <LifeIcon />
                </ListItemIcon>
                <ListItemText primary="2 Stocks" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <TimeIcon />
                </ListItemIcon>
                <ListItemText primary="6 minutes" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <StageIcon />
                </ListItemIcon>
                <ListItemText primary="Loser Picks Stage" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <HazardsIcon />
                </ListItemIcon>
                <ListItemText primary="Hazards Off" secondary="Group dependent - can be good for counter picks" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <ItemsIcon />
                </ListItemIcon>
                <ListItemText primary="Items Off" secondary="Group dependent - can be useful equalizers" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="h5" className={classes.subtitle}>
              GAMEPLAY
            </Typography>
            <List component="nav" aria-label="main mailbox folders">
              <ListItem>
                <ListItemText primary="Everyone starts on the top left character."  secondary="In a traditional game of Smashopoly, this means Mario." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Move from left to right. Once you get to the end of a row, move down a row and start from the far left." />
              </ListItem>
              <ListItem>
                <ListItemText primary="Travel through the entire board until you land on random. To win the game, you must get 1st on random." />
              </ListItem>
              <ListItem>
                <ListItemText primary="A player may move backwards as many as they would like, but must play from that character's position."
                secondary="Meaning, they are effectively sacrificing a movement/s by returning to a previous character." />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Typography variant="h5" className={classes.subtitle}>MOVEMENTS</Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">1st Place</TableCell>
                  <TableCell align="right">3 Movements</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">2nd Place</TableCell>
                  <TableCell align="right">2 Movements</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">3rd Place</TableCell>
                  <TableCell align="right">1 Movement</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">Most Damage</TableCell>
                  <TableCell align="right">1 Movement</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">KOs</TableCell>
                  <TableCell align="right">1 Movement per KO</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
        </Grid>
        <div style={{ textAlign: "left", padding: "15px 0 0 0" }}>
          <Typography variant="h6">
            <strong>EXAMPLE:</strong> In the original ordering, a player gets 2nd place (2), with 3 KOs (3) and has highest damage (1) playing
            with Mario - therefore, they earn 6 moves (2+3+1), taking them from Mario to Kirby (in SSB Utimate). In the next game, you will
            play as Kirby, therefore skipping DK, Link, Samus, Dark Samus, and Yoshi.
          </Typography>
        </div>
        <Divider className={classes.divider} />
        <div style={{ textAlign: "justify", padding: 5 }}>
          <Typography variant="h4" className={classes.siteInfo}>PLAY.SMASHOPOLY</Typography>
          <Typography variant="h6">
            Use this site to track players' movements and change the ordering (my group
            got tired of starting on Mario). <br />
            Eventually, there will be a way to share a game with other players to help facilitate
            online play... eventually...
          </Typography>
        </div>
      </Paper>
    </Grid>
  );

export default withStyles(styles)(About);
