import React from 'react';
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Button, useScrollTrigger, Slide } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  linkButton: {
    margin: "0 2px",
    textDecoration: "none",
    color: "#fff"
  },
  title: {
    fontFamily: "SmashBros",
    textShadow: "0px 0px 4px #999",
    color: "#EEE",
    [theme.breakpoints.down('xs')]: {
      fontSize: 20
    }
  },
  titleLink: {
    textDecoration: "none",
    flexGrow: 1,
    cursor: "pointer"
  },
  navBar: {
    backgroundColor: "#222"
  }
});


function HideOnScroll({ children }) {
  const trigger = useScrollTrigger({ threshold: 50 });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
};

const NavBar = (props) => (
  <HideOnScroll {...props}>
    <AppBar className={props.classes.navBar}>
      <Toolbar variant="dense">
        <Link to="/" className={props.classes.titleLink}>
          <Typography variant="h4" className={props.classes.title}>
            SMASHOPOLY
          </Typography>
        </Link>
        <Link className={props.classes.linkButton} to="/about">
          <Button color="inherit">How To Play</Button>
        </Link>
        {/* <Button className={props.classes.linkButton} color="inherit">Login</Button> */}
      </Toolbar>
    </AppBar>
  </HideOnScroll>
);

export default withStyles(styles)(NavBar);
